import SidebarMenu from "./SidebarMenu"

function Header() {
    return (
        <div style={{
            backgroundColor: 'rgb(43, 43, 43)',
            height: '100px',

            display: 'flex',
            flexDirection: 'row',

        }}>
            <div style={{
                width: '15%',
                paddingLeft: '20px',
                marginRight: '20px',
                paddingTop:'10px'
            }}>
                <p style={styles.wrappedText1}>
                <SidebarMenu />
                </p>
                <p style={styles.subHeader}>&nbsp;</p>
            </div>

            <div style={{
                width: '85%',
                display: 'flex',
                height: '50%',
                flexDirection: 'column',



            }}>
                <p style={styles.wrappedText1}>Dan Chrostowski</p>
                <p style={styles.subHeader}>Full-Stack Developer</p>

            </div>
        </div>
    )
}

const styles = {
    containerDiv: {
        backgroundColor: 'rgb(43, 43, 43)',
        height: '150px',
        position: 'aboslute',
        display: 'flex',
        flexDirection: 'row',

    },

    menuContainer: {
        width: '15%',
        paddingLeft: '20px'
    },

    headerTextWrapper: {
        width: '85%',
        display: 'flex',
        flexDirection: 'column',
        height: '25%',
        alignSelf: 'flex-center'


    },

    wrappedText1: {
        color: 'white',
        fontSize: '36px',
        lineHeight: '50px',
        marginTop: '12px',

    },
    subHeader: {
        color: 'white',
        fontSize: '24px',
        marginTop: '-30px',
        paddingLeft: '30px'

    },

    wrappedText2: {
        color: 'white',
        fontSize: '24px',
        marginTop: '-10px'
    }


}

export default Header