import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './skills.css'

import React, { useRef, useState, useEffect } from 'react'
import { MDBBadge } from 'mdb-react-ui-kit';
import { Rating } from '@mui/material';
import skills from './skills.json'

import useWindowDimensions from '../hooks/useWindowDimensions';

function Skills() {
    const [rowHeight, setRowHeight] = useState(0)
    const ratingRef = useRef(null)
    const windowDimensions = useWindowDimensions()
    const langRef = useRef(null)
    const fplRef = useRef(null)
    const intRef = useRef(null)

    const [widestSection, setWidestSection] = useState(null)

    useEffect(() => {
        if (typeof ratingRef?.current?.clientHeight === 'number') {
            setRowHeight(ratingRef?.current?.clientHeight)
        }

    }, [ratingRef])


    useEffect(() => {
        setWidestSection(windowDimensions.width*0.8)
    }, [windowDimensions.width])



    const renderSkillRow = (skillLabel, rating) => {
        return (
            <tr style={{ height: rowHeight - 5 }}>
                <td>{skillLabel ? <MDBBadge className="mx-2" color='dark'>{skillLabel}</MDBBadge> : <div style={{ height: rowHeight + 30 }}>&nbsp;</div>}</td>
                <td> {skillLabel ? <Rating ref={ratingRef === null ? ratingRef : null} readOnly value={rating} precision={0.5} /> : <div style={{ height: rowHeight }}></div>}</td>
            </tr>
        )

    }

    const renderSkillTables = (skillTables, skillMap) => {
        return skillTables.map(skillSet => {
            return (
                <table className="skill-table">
                    <tbody>
                        {skillSet.map(skill => renderSkillRow(skill, skillMap[skill]))}
                    </tbody>
                </table>
            )
        })
    }

    const renderSubInterestRows = (subInterests) => {
        return (
            <ul>
                {subInterests.map(si => <li><span className="sub-interest">{si}</span><br /></li>)}
            </ul>
        )
    }


    const renderInterestTables = (interestTables, interestMap) => {
        return (
            interestTables.map((mainInterestTable) => {
                return (
                    <table style={{ marginLeft: '1em' }}>
                        <tbody>
                            {mainInterestTable.map((mainInterest) => {
                                if (mainInterest) {
                                    return (
                                        <>
                                            <tr>
                                                <td style={{ verticalAlign: 'top' }}><span className="main-interest">
                                                    {mainInterest}
                                                </span><br />
                                                    {renderSubInterestRows(skills.interests[mainInterest])}
                                                </td>
                                            </tr>
                                        </>
                                    )

                                }
                                else {
                                    return null
                                }
                            })}
                        </tbody>
                    </table>)
            })

        )
    }


    const splitSkillsToTables = (skillMap) => {
        const rowLength = parseInt(Object.keys(skillMap).length / (parseInt(windowDimensions.width / 330) || 1)) + 1
        const allSkillTables = []
        let curTable = []
        let lastIdx = Object.keys(skillMap).length - 1
        Object.keys(skillMap).sort().forEach((skill, idx) => {
            if (idx !== 0 && idx % rowLength === 0) {
                allSkillTables.push(curTable)
                curTable = []
            }
            curTable.push(skill)
            lastIdx = idx
        })

        for (let i = lastIdx + 1; i % rowLength !== 0; i++) {
            curTable.push('')
        }

        allSkillTables.push(curTable)

        return allSkillTables

    }

    const allLangTables = splitSkillsToTables(skills.languages)
    const allFplTables = splitSkillsToTables(skills.fpl)
    const allInterestTables = splitSkillsToTables(skills.interests)

    return (
        <div style={{ marginLeft: '2em' }}>
            <h2>Skill Assessment & Areas of Interest</h2>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

                <p style={{ fontSize: '18px' }}>
                    Listed below is a brief self-assessment of my current knowledge and proficiency from a selection of
                    programming languages, platforms, frameworks, and libraries alongside a list of specific areas
                    of study/practice I am most interested in pursuing.

                </p>
                <div id="languages" style={{ width: widestSection }}>
                    <div className="skill-table-container" ref={langRef}>
                        <div style={{ textAlign: 'left', marginLeft: '1em' }}>
                            <h3>Programming Languages</h3>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row" }}>
                            {renderSkillTables(allLangTables, skills.languages)}
                        </div>
                    </div>
                </div>
                <br />
                <div id="fpl" style={{ width: widestSection }}>
                    <div className="skill-table-container" ref={fplRef}>
                    <div style={{marginLeft:'1em', textAlign: 'left' }}>
                            <h3>Libraries, Platforms, Frameworks, etc.</h3>
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row" }}>
                            {renderSkillTables(allFplTables, skills.fpl)}
                        </div>
                    </div>


                </div>
                <br />
                <div id="interests" style={{ width: widestSection }}>
                    <div  className="skill-table-container" ref={intRef}>
                        <div style={{
                            marginLeft:'1em',
                            textAlign: 'left',
                            backgroundColor: 'lightgrey',
                            }}>

                            <h3 >Areas of Interest</h3>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {renderInterestTables(allInterestTables)}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Skills