import React from "react"
import { Link } from 'react-router-dom'
import { Nav } from "react-bootstrap"
import "./menu.css"

function Menu({ onClickLink }) {
    return (
        <div className="navigation">
            <Nav className="flex-column" onClick={onClickLink}>
                <div style={{marginTop:'-1em', marginLeft: '-0.5em', display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: 'white', fontSize: '0.6em', fontWeight: 'bold' }}>This Site</span>
                    <div className="nav-group" style={{ marginLeft: '0.5em' }}>
                        <Nav.Item style={{ fontSize: "0.5em", marginLeft: '0em' }}>
                            <Link to="/">Home</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/skills-experience">Skills/Experience</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="https://resume.danchrostowski.com/">Resume</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/contact">Contact</Link>
                        </Nav.Item>
                    </div>
                    
                    <span style={{ color: 'white', fontSize: '0.6em', fontWeight: 'bold' }}>Personal Projects</span>
                        <div className="nav-group" style={{ marginLeft: '0.5em' }}>
                            <Nav.Item>
                                <Link to="https://pokerapp.danchrostowski.com">Poker App</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="/streetscrape-about/">StreetScrape</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="https://git.streetscrape.com/dan/danchrostowski.com">danchrostowski.com (this website)</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="https://git.streetscrape.com">Personal GitLab Server</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="https://mail.streetscrape.com">Personal Mail Server</Link>
                            </Nav.Item>
                        </div>
                    

                    
                    <span style={{ color: 'white', fontSize: '0.6em', fontWeight: 'bold' }}>Other Profiles</span>
                    <div className="nav-group" style={{ marginLeft: '0.5em' }}>
                            <Nav.Item>
                                <Link to="https://github.com/dchrostowski">GitHub</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="https://stackexchange.com/users/4417010/dan-chrostowski">StackExchange Profile</Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Link to="https://www.linkedin.com/in/dan-chrostowski">LinkedIn</Link>
                            </Nav.Item>
                        </div>
                    
                </div>
            </Nav>
        </div>
    )
}

export default Menu