import logo from './logo.svg';
import './App.css';


import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Rating from '@mui/material/Rating';

import Header from './components/Header';
import Layout from './components/Layout';
import Skills from './components/Skills';
import prof_photo from './images/professional_photo.png'
import Resume from './components/Resume'
import streetscrapeScreenshot from './assets/streetscrape_screenshot.png'




function App() {

  

  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/streetscrape-about" element={<StreetscrapeAbout />} />
          <Route path="/skills-experience" element={<Skills />}/>
          <Route path="/contact" element={<Contact />}/>


        </Routes>
      </Layout>
    </Router>
  );

  function Home() {
    return (
      <>
        <div style={{
          display: 'flex', flexDirection: 'row', paddingLeft: '30px', position: 'absolute'
        }}>
          <div className="photoContainer" style={{ width: '25%' }}>
            <img src={prof_photo} style={{ paddingTop: '30px', height: 'auto', width: '75%' }} alt="Dan Chrostowski" />
          </div>

          <div style={{ width: '75%', display: 'flex', flexDirection: 'column', marginLeft: '-1em', 'paddingRight': '30px' }}>
            <h2>About Me</h2>
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'top' }}>

              <p style={{ fontSize: '18px', marginTop: "-10px" }}>Hello, I'm Dan.  Software development has been a lifetime passion of mine for as long as I can remember.
                I wrote my first lines of HTML code at age eleven and steadily expanded my knowledge to a multitude of programming languages and frameworks.
                In 2007 I left my home in Darien, Connecticut to attend Marquette University in Milwaukee, WI.
                At Marquette, I started in the College of Engineering, initially declaring my major in Electrical and Computer Engineering.
                During my sophomore year, I decided to switch to Computer Science with a minor in Philosophy.  In my senior year at Marquette, I was recruited by my classmates to be 
                part of the coveted <a href="https://www.slideshare.net/WiCleanCities/corliss-marquette-elimo">eLIMO senior design project</a>.
                My role was to design, develop and implement a digital odometer and speedometer for a Ford E150 which was converted to a fully electric vehicle.<br /><br />
                I graduated from Marquette in 2011, earning a Bachelor of Science degree in Computer Science.  Shortly after graduation I moved to Los Angeles where I began 
                my professional career.  In California, I ended up working for many start-ups, all of which ended up going out of business.  In 2017 I decided to move to Minneapolis, MN 
                where I was able to secure a more stable employment opportunity with Blue Cross & Blue Shield of Minnesota.
                A few years later around 2020 I moved to Colorado and began working as a software engineering consultant for <a href="https://kinandcarta.com">Kin + Carta</a> and where I got the opportunity to
                work on a multitude of projects for various customers and clients.<br /><br />
                In my spare time outside of work, I really enjoy working on personal coding projects and spending time outdoors.  In the summer I often go hiking and backpacking 
                in the Rockies with friends and in the winter I enjoy skiing nearly every weekend.  I also have a keen interest in online poker and have done 
                some <a href="https://pokerapp.danchrostowski.com">freelance development work</a> for a few online poker sites.
              </p>

            </div>
          </div>



        </div >
      </>

    );
  }

  

  function StreetscrapeAbout() {
    const screencapWidth = window.innerWidth - 40 
    return (
      <div style={{marginLeft:'1em'}}>
        <h2><a href="https://streetscrape.com/">StreetScrape</a></h2>
        StreetScrape was a project I started in 2015 which attempted to make stock market predictions by use of data mining 
        financial data from a large variety of resources available on the Internet.  The data mining was
        carried out primarily by use of automated web crawling and scraping techniques that I learned while working for
        startups in California.  I eventually abandoned the project, realizing my knowledge of financial markets fell far 
        short of what was needed to make the project a success.  I resurrected a very small portion of the project and
        re-deployed a few of the web crawlers which capture simple buy/sell recommendations from three "reputable" websites.
        I then created a React app which graphs the recommendations against the stock price of a given stock.  Go to <a href="https://streetscrape.com">streetscrape.com</a> to see how it works.
      <br/><br/>
      <img src={streetscrapeScreenshot} alt="Screen capture of streetscrape.com" style={{maxWidth:screencapWidth}}/>
      </div>
    );
  }

  function Contact() {
    return (
      <div style={{marginLeft:'1em'}}>
        <h2>Contact Info</h2>
        <span>Phone: +1 (213) 603-0285</span><br/>
        <span>Email: dan@danchrostowski.com</span>
      </div>
    );
  }
}

export default App;
